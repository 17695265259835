@import url('https://fonts.googleapis.com/css2?family=Hack&display=swap');

.App {
  text-align: center;
  background-color: #282c34;
  font-family: 'Hack', monospace; 
}

.open-logo {
  width: 99%;
  max-width: 500px;
}

.App-content {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: silver; 
  width: 90%;
  padding-left: 15px;
}

.cta-button {
  background-color: #ff6600;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.tokenInfo{
  display: flex;
  justify-content: center;
  align-items: center;
}

.startMessage{
  display: flex;
  justify-content: center;
  align-items: center;
}

.token-text{
  color: white;
  font-size: 0.7rem;
  padding-left: 10px;
}

.start-text{
  color: red;
  font-size: 0.7rem;
  padding-left: 10px;
}

.success-message {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 0.8rem;
  text-align: center;
  a {
    color: #ff6600;
  }
}

.x-logo{
  width: 15px;
  height: 15px;
  padding-left: 10px;
}

.footer-text{
  color: white;
  font-size: 0.8rem;
  padding-left: 3px;
}

.acc-text{
  color: #ff6600;
  font-size: 0.7rem;
  width: 95%;
}

.header{
  font-size: 2rem;
}

.sub-text{
  font-size: 1.2rem;
  align-items: top;
  margin-top: -25px;
}

.sub-text2{
  font-size: 1.2rem;
}

.explain-text{
  font-size: 0.6rem;
  color: #808080;
  display: flex;
  justify-content: center;
  align-items: top;
  width: 80%;
  max-width: 600px;
  text-align: center;
}

.bal-text{
  color: white;
  font-size: 1.0rem;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
